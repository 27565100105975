import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null;

    // jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig};

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false;

    // For Refreshing Token
    subscribers = [];

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns;
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig};

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getToken();

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const {config, response} = error;
                const originalRequest = config;

                // if (status === 401) {
                if (response && response.status === 401) {
                    const lang =
                        localStorage.getItem('lang') == 'sv'
                            ? error.response.data.message
                            : error.response.data.eng_message;
                    return {
                        status: 401,
                        message: lang || error.response.data.detail,
                    };
                }
                return Promise.reject(error);
            }
        );
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback =>
            callback(accessToken)
        );
    }

    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
    }

    requestResetPassword(...args) {
        return this.axiosIns.post(this.jwtConfig.requestResetPassword, ...args);
    }

    resetUpdatePassword(...args) {
        return this.axiosIns.post(this.jwtConfig.resetPassword, ...args);
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
    }

    packages(...args) {
        return this.axiosIns.get(this.jwtConfig.packages, ...args);
    }

    subscription(...args) {
        return this.axiosIns.get(this.jwtConfig.subscriptions, ...args);
    }

    updatePackages(uuid, statusUpdate, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}/${uuid}/${statusUpdate}`,
            ...args
        );
    }

    updateSubscription(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}${uuid}`,
            ...args
        );
    }

    cancelSubscription(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}/${uuid}/cancel`,
            ...args
        );
    }
    changePaymentPlan(args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}/${args.id}/change_plan`,
            args.payment_plan
        );
    }

    getCurrentDuplicates() {
        return this.axiosIns.get(this.jwtConfig.currentDuplicates);
    }

    getUsers() {
        return this.axiosIns.get(this.jwtConfig.users);
    }

    getMyAccount() {
        return this.axiosIns.get(`${this.jwtConfig.users}/me`);
    }

    updateUsers(uuid, ...args) {
        return this.axiosIns.put(`${this.jwtConfig.users}/${uuid}`, ...args);
    }

    getUsersByid(uuid) {
        return this.axiosIns.get(`${this.jwtConfig.users}/${uuid}`);
    }

    changePassword(...args) {
        return this.axiosIns.post(this.jwtConfig.changePassword, ...args);
    }

    updateUser(uuid, ...args) {
        return this.axiosIns.put(`${this.jwtConfig.users}/${uuid}`, ...args);
    }
    deleteUser(uuid) {
        return this.axiosIns.delete(`${this.jwtConfig.users}/${uuid}`);
    }

    inviteUsers(...args) {
        return this.axiosIns.post(`${this.jwtConfig.users}/invite`, ...args);
    }

    updateImage(...args) {
        return this.axiosIns.put(`${this.jwtConfig.users}/image`, ...args);
    }

    getCompanyList() {
        return this.axiosIns.get(this.jwtConfig.company);
    }

    getCompanyByid(uuid) {
        return this.axiosIns.get(`${this.jwtConfig.company}/${uuid}`);
    }

    getCurrentUsers() {
        return this.axiosIns.get(`${this.jwtConfig.users}/me`);
    }

    openTicket(...args) {
        return this.axiosIns.post(this.jwtConfig.openTicket, ...args);
    }

    getDuplicatesList(...args) {
        return this.axiosIns.get(this.jwtConfig.duplicates, ...args);
    }

    getDuplicatesByid(uuid) {
        return this.axiosIns.get(`${this.jwtConfig.duplicates}/${uuid}`);
    }

    getDuplicateDetails(uuid) {
        return this.axiosIns.get(
            `${this.jwtConfig.duplicates}/${uuid}/details`
        );
    }

    updateDuplicates(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.duplicates}/${uuid}`,
            ...args
        );
    }
    getHandleGroup(uuid) {
        return this.axiosIns.get(
            `${this.jwtConfig.duplicates}/${uuid}/handle_group`
        );
    }

    updateHandleGroup(uuid) {
        return this.axiosIns.put(
            `${this.jwtConfig.duplicates}/${uuid}/handle_group`
        );
    }

    notDuplicate(uuid, document_num) {
        return this.axiosIns.put(
            `${this.jwtConfig.duplicates}/${uuid}/not_duplicate/${document_num}`
        );
    }

    handleSingle(uuid, document_num) {
        return this.axiosIns.put(
            `${this.jwtConfig.duplicates}/${uuid}/handle_single/${document_num}`
        );
    }

    getFieldMapper(...args) {
        return this.axiosIns.get(this.jwtConfig.fieldMapper, ...args);
    }

    getNotificationsList(...args) {
        return this.axiosIns.get(this.jwtConfig.notifications, ...args);
    }
    getNotificationsById(uuid) {
        return this.axiosIns.get(`${this.jwtConfig.notifications}/${uuid}`);
    }

    deleteNotifications(uuid) {
        return this.axiosIns.delete(`${this.jwtConfig.notifications}/${uuid}`);
    }
    updateNotifications(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.notifications}/${uuid}`,
            ...args
        );
    }
    getBillingsList(...args) {
        return this.axiosIns.get(this.jwtConfig.billing, ...args);
    }
    downLoadFile(id) {
        return this.axiosIns.get(`${this.jwtConfig.billing}/${id}/download`);
    }

    getBillingsDetail() {
        return this.axiosIns.get(`${this.jwtConfig.billing}/detail`);
    }

    updateBillingsDetail(...args) {
        return this.axiosIns.put(`${this.jwtConfig.billing}/detail`, ...args);
    }

    blogDetails(slug) {
        return this.axiosIns.get(`${this.jwtConfig.listBlog}/${slug}`);
    }

    configure(...args) {
        return this.axiosIns.get(this.jwtConfig.configure, ...args);
    }

    updateConfigure(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.configure}/${uuid}`,
            ...args
        );
    }

    transactionUnique(...args) {
        return this.axiosIns.get(this.jwtConfig.transaction, ...args);
    }

    getResourceList(...args) {
        return this.axiosIns.get(this.jwtConfig.availableResource, ...args);
    }

    faqCategories(...args) {
        return this.axiosIns.get(this.jwtConfig.faqCategories, ...args);
    }

    faqList(...args) {
        if (args) {
            return this.axiosIns.get(
                `${this.jwtConfig.listfaqByCategories}/${args}`
            );
        } else
            return this.axiosIns.get(
                this.jwtConfig.listfaqByCategories,
                ...args
            );
    }

    eventLogList(...args) {
        return this.axiosIns.get(this.jwtConfig.eventLog, ...args);
    }

    tierList() {
        return this.axiosIns.get(this.jwtConfig.tierList);
    }

    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken(),
        });
    }

    guide(...args) {
        return this.axiosIns.get(this.jwtConfig.guide, ...args);
    }
}
