export default {
    // Endpoints Duplio
    loginEndpoint: 'auth/login',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
    requestResetPassword: 'auth/request_password_reset',
    resetPassword: 'auth/reset_password',

    //New duplio
    currentDuplicates: 'current_duplicates',

    duplicates: 'duplicates',

    //users
    users: 'users',

    company: 'company',

    //support
    openTicket: 'open_ticket',

    //notification
    notifications: 'notifications',

    // //Dashboard
    billing: 'billings',
    // billingDetail: 'billings/detail/',

    // //Tier
    tierList: 'duplio_tiers',

    // //Configure / Settings
    configure: 'configurations',

    //Field mapper
    fieldMapper: "field_mapper",

    transaction: 'transaction-id-fields',

    //Available Resource
    availableResource: 'available-resources',

    // //Subscription Packages
    packages: 'duplio_packages',
    subscriptions: 'subscriptions',

    // //Faq
    faqCategories: 'faq-categories',
    listfaqByCategories: 'faq',

    changePassword: 'auth/change_password',

    //guide 
    guide: 'guides',

    // event-log
    eventLog: 'event_logs',

    //Blogs
    listBlog: 'blogs',
    blogCategories: 'blog-categories',

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'token',

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
};
